export default function FourZeroFour(){
    return (
        <>
        <center>
            <h2 style={{marginTop: "100px"}}>404 Error</h2>
            <p>Page not Found</p>
            <a href="/"><button style={{marginBottom: "150px"}}>Go Home</button></a>
        </center>
        </>
    );
}
